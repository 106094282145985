module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-XZLRLCM7RL"],"pluginConfig":{"head":true,"respectDNT":false,"exclude":[],"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0},"gtagConfig":{}},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"html":{"useGatsbyImage":false,"imageMaxWidth":null,"fallbackImageMaxWidth":1024,"imageQuality":70,"createStaticFiles":true,"generateWebpImages":true,"generateAvifImages":false,"placeholderType":"dominantColor"},"url":"https://blog-stage.typtap.com/graphql","debug":{"timeBuildSteps":true,"graphql":{"showQueryVarsOnError":true,"copyQueryOnError":true,"showQueryOnError":true,"panicOnError":false,"onlyReportCriticalErrors":true,"copyNodeSourcingQueryAndExit":false,"writeQueriesToDisk":false,"printIntrospectionDiff":false},"preview":false,"disableCompatibilityCheck":false,"throwRefetchErrors":false},"schema":{"perPage":19,"requestConcurrency":5,"previewRequestConcurrency":2,"timeout":30000,"queryDepth":15,"circularQueryLimit":5,"typePrefix":"Wp"},"develop":{"nodeUpdateInterval":60000,"hardCacheMediaFiles":false,"hardCacheData":false},"type":{"User":{"exclude":true},"UserRole":{"exclude":true},"Tag":{"exclude":true},"PostFormat":{"exclude":true},"Menu":{"exclude":true},"MenuItem":{"exclude":true},"ContentType":{"exclude":true},"Comment":{"exclude":true},"MediaItem":{"createFileNodes":false,"localFile":{"maxFileSizeBytes":1,"requestConcurrency":20,"excludeByMimeTypes":[]},"excludeFieldNames":["seo","ancestors","lastEditedBy","authorDatabaseId","contentTypeName","enclosure","isContentNode","isTermNode","modified","modifiedGmt","parentDatabaseId","srcSet","parent","children"],"placeholderSizeName":"gatsby-image-placeholder","lazyNodes":false},"RootQuery":"{ excludeFieldNames: ['viewer', 'node', 'schemaMd5'], },"},"verbose":true,"catchLinks":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Typtap Insurance","short_name":"typtap.com","start_url":"/","background_color":"#ffffff","theme_color":"#000000","display":"standalone","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"3b4d4e591956e96453a250fb70b87c15"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
